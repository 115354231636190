export enum AlertTypeEnum {
    Info,
    Warning,
    Danger,
    Success,
}

export interface Languages {
    label: string;
    value: string;
}

export enum JobApplicationStatusEnum {
    Submitted = 'Submitted',
    UpdateInfoRequested = 'UpdateInfoRequested',
    Investigation = 'Investigation',
    RejectedApplication = 'RejectedApplication',
    RejectedOffer = 'RejectedOffer',
    Withdrawn = 'Withdrawn',
    Offered = 'Offered',
    Accepted = 'Accepted',
    Testing = 'Testing',
    Hired = 'Hired'
}

export interface SignatureDetials {
    signerName: string;
    signerEmail: string;
    ipAddress: string;
    deviceInfo: string;
    singingDate: string;
    signingTimeStamp: string;
    signedDocumentId: number;
    id: 1;
}

import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { AlertTypeEnum } from './../../core/models/shared.model';
import { Injectable, TemplateRef } from '@angular/core';
import { DropdownList } from '../model/shared.model';
import { MessageService } from 'primeng/api';

const APP_MENU_OPEND_KEY = 'menu-opend';

@Injectable({
    providedIn: 'root',
})
export class SharedService {
    constructor(
        private translate: TranslateService,
        private messageService: MessageService
    ) { }
    opened =
        window.localStorage.getItem(APP_MENU_OPEND_KEY) == 'true'
            ? true
            : false;
    menuOpend$ = new BehaviorSubject<boolean>(this.opened);
    $driver_card: TemplateRef<any> | null = null;
    $experience_card: TemplateRef<any> | null = null;
    $residence_card: TemplateRef<any> | null = null;
    $employment_card: TemplateRef<any> | null = null;
    $violation_card: TemplateRef<any> | null = null;
    $incident_card: TemplateRef<any> | null = null;
    $job_openings: TemplateRef<any> | null = null;
    $previous_employers_card: TemplateRef<any> | null = null;
    $cdl_card: TemplateRef<any> | null = null;
    $truck_card: TemplateRef<any> | null = null;
    $trailer_card: TemplateRef<any> | null = null;
    $company_address_card: TemplateRef<any> | null = null;
    $company_contact_card: TemplateRef<any> | null = null;
    $supplier_contact_card: TemplateRef<any> | null = null;
    $company_other_documents_card: TemplateRef<any> | null = null;
    $inspection_report_card: TemplateRef<any> | null = null;
    $incidents_card: TemplateRef<any> | null = null;
    $drug_test_card: TemplateRef<any> | null = null;
    $allowEditButton: TemplateRef<any> | null = null;
    $insuranceCard: TemplateRef<any> | null = null;
    $endrosments_card: TemplateRef<any> | null = null;
    $claims_card: TemplateRef<any> | null = null;
    $employee_card: TemplateRef<any> | null = null;

    alert(summary: string, detail: string, type: AlertTypeEnum) {
        switch (type) {
            case AlertTypeEnum.Danger:
                this.messageService.add({
                    severity: 'error',
                    summary: summary,
                    detail: detail
                });
                break;
            case AlertTypeEnum.Info:
                this.messageService.add({
                    severity: 'info',
                    summary: summary,
                    detail: detail
                });
                break;
            case AlertTypeEnum.Success:
                this.messageService.add({
                    severity: 'success',
                    summary: summary,
                    detail: detail,
                    icon: 'icon-check-circle'
                });
                break;
            case AlertTypeEnum.Warning:
                this.messageService.add({
                    severity: 'warn',
                    summary: summary,
                    detail: detail
                });
                break;
        }
    }

    async alertSuccessMessage(
        title: string = 'Changes Saved!',
        desc: string = 'Your changes have been saved successfully.'
    ) {
        this.alert(title, desc, AlertTypeEnum.Success);
    }

    async alertDangerMessage(
        title: string = 'Changes were not saved!',
        desc: string = 'Something went wrong!'
    ) {
        this.alert(title, desc, AlertTypeEnum.Danger);
    }

    arrayEquals(a: [], b: []) {
        return (
            Array.isArray(a) &&
            Array.isArray(b) &&
            a.length === b.length &&
            a.every((val, index) => val === b[index])
        );
    }

    languagesList() {
        return [
            {
                code: 'ab',
                name: 'Abkhaz',
            },
            {
                code: 'aa',
                name: 'Afar',
            },
            {
                code: 'af',
                name: 'Afrikaans',
            },
            {
                code: 'ak',
                name: 'Akan',
            },
            {
                code: 'sq',
                name: 'Albanian',
            },
            {
                code: 'am',
                name: 'Amharic',
            },
            {
                code: 'ar',
                name: 'Arabic',
            },
            {
                code: 'an',
                name: 'Aragonese',
            },
            {
                code: 'hy',
                name: 'Armenian',
            },
            {
                code: 'as',
                name: 'Assamese',
            },
            {
                code: 'av',
                name: 'Avaric',
            },
            {
                code: 'ae',
                name: 'Avestan',
            },
            {
                code: 'ay',
                name: 'Aymara',
            },
            {
                code: 'az',
                name: 'Azerbaijani',
            },
            {
                code: 'bm',
                name: 'Bambara',
            },
            {
                code: 'ba',
                name: 'Bashkir',
            },
            {
                code: 'eu',
                name: 'Basque',
            },
            {
                code: 'be',
                name: 'Belarusian',
            },
            {
                code: 'bn',
                name: 'Bengali',
            },
            {
                code: 'bh',
                name: 'Bihari',
            },
            {
                code: 'bi',
                name: 'Bislama',
            },
            {
                code: 'bs',
                name: 'Bosnian',
            },
            {
                code: 'br',
                name: 'Breton',
            },
            {
                code: 'bg',
                name: 'Bulgarian',
            },
            {
                code: 'my',
                name: 'Burmese',
            },
            {
                code: 'ca',
                name: 'Catalan',
            },
            {
                code: 'ch',
                name: 'Chamorro',
            },
            {
                code: 'ce',
                name: 'Chechen',
            },
            {
                code: 'ny',
                name: 'Chichewa',
            },
            {
                code: 'zh',
                name: 'Chinese',
            },
            {
                code: 'cv',
                name: 'Chuvash',
            },
            {
                code: 'kw',
                name: 'Cornish',
            },
            {
                code: 'co',
                name: 'Corsican',
            },
            {
                code: 'cr',
                name: 'Cree',
            },
            {
                code: 'hr',
                name: 'Croatian',
            },
            {
                code: 'cs',
                name: 'Czech',
            },
            {
                code: 'da',
                name: 'Danish',
            },
            {
                code: 'dv',
                name: 'Divehi',
            },
            {
                code: 'nl',
                name: 'Dutch',
            },
            {
                code: 'dz',
                name: 'Dzongkha',
            },
            {
                code: 'en',
                name: 'English',
            },
            {
                code: 'eo',
                name: 'Esperanto',
            },
            {
                code: 'et',
                name: 'Estonian',
            },
            {
                code: 'ee',
                name: 'Ewe',
            },
            {
                code: 'fo',
                name: 'Faroese',
            },
            {
                code: 'fj',
                name: 'Fijian',
            },
            {
                code: 'fi',
                name: 'Finnish',
            },
            {
                code: 'fr',
                name: 'French',
            },
            {
                code: 'ff',
                name: 'Fula',
            },
            {
                code: 'gl',
                name: 'Galician',
            },
            {
                code: 'ka',
                name: 'Georgian',
            },
            {
                code: 'de',
                name: 'German',
            },
            {
                code: 'el',
                name: 'Greek',
            },
            {
                code: 'gn',
                name: 'GuaranÃ­',
            },
            {
                code: 'gu',
                name: 'Gujarati',
            },
            {
                code: 'ht',
                name: 'Haitian',
            },
            {
                code: 'ha',
                name: 'Hausa',
            },
            {
                code: 'he',
                name: 'Hebrew',
            },
            {
                code: 'hz',
                name: 'Herero',
            },
            {
                code: 'hi',
                name: 'Hindi',
            },
            {
                code: 'ho',
                name: 'Hiri Motu',
            },
            {
                code: 'hu',
                name: 'Hungarian',
            },
            {
                code: 'ia',
                name: 'Interlingua',
            },
            {
                code: 'id',
                name: 'Indonesian',
            },
            {
                code: 'ie',
                name: 'Interlingue',
            },
            {
                code: 'ga',
                name: 'Irish',
            },
            {
                code: 'ig',
                name: 'Igbo',
            },
            {
                code: 'ik',
                name: 'Inupiaq',
            },
            {
                code: 'io',
                name: 'Ido',
            },
            {
                code: 'is',
                name: 'Icelandic',
            },
            {
                code: 'it',
                name: 'Italian',
            },
            {
                code: 'iu',
                name: 'Inuktitut',
            },
            {
                code: 'ja',
                name: 'Japanese',
            },
            {
                code: 'jv',
                name: 'Javanese',
            },
            {
                code: 'kl',
                name: 'Kalaallisut',
            },
            {
                code: 'kn',
                name: 'Kannada',
            },
            {
                code: 'kr',
                name: 'Kanuri',
            },
            {
                code: 'ks',
                name: 'Kashmiri',
            },
            {
                code: 'kk',
                name: 'Kazakh',
            },
            {
                code: 'km',
                name: 'Khmer',
            },
            {
                code: 'ki',
                name: 'Kikuyu',
            },
            {
                code: 'rw',
                name: 'Kinyarwanda',
            },
            {
                code: 'ky',
                name: 'Kyrgyz',
            },
            {
                code: 'kv',
                name: 'Komi',
            },
            {
                code: 'kg',
                name: 'Kongo',
            },
            {
                code: 'ko',
                name: 'Korean',
            },
            {
                code: 'ku',
                name: 'Kurdish',
            },
            {
                code: 'kj',
                name: 'Kwanyama',
            },
            {
                code: 'la',
                name: 'Latin',
            },
            {
                code: 'lb',
                name: 'Luxembourgish',
            },
            {
                code: 'lg',
                name: 'Ganda',
            },
            {
                code: 'li',
                name: 'Limburgish',
            },
            {
                code: 'ln',
                name: 'Lingala',
            },
            {
                code: 'lo',
                name: 'Lao',
            },
            {
                code: 'lt',
                name: 'Lithuanian',
            },
            {
                code: 'lu',
                name: 'Luba-Katanga',
            },
            {
                code: 'lv',
                name: 'Latvian',
            },
            {
                code: 'gv',
                name: 'Manx',
            },
            {
                code: 'mk',
                name: 'Macedonian',
            },
            {
                code: 'mg',
                name: 'Malagasy',
            },
            {
                code: 'ms',
                name: 'Malay',
            },
            {
                code: 'ml',
                name: 'Malayalam',
            },
            {
                code: 'mt',
                name: 'Maltese',
            },
            {
                code: 'mi',
                name: 'MÄori',
            },
            {
                code: 'mr',
                name: 'Marathi',
            },
            {
                code: 'mh',
                name: 'Marshallese',
            },
            {
                code: 'mn',
                name: 'Mongolian',
            },
            {
                code: 'na',
                name: 'Nauru',
            },
            {
                code: 'nv',
                name: 'Navajo',
            },
            {
                code: 'nb',
                name: 'Norwegian BokmÃ¥l',
            },
            {
                code: 'nd',
                name: 'North Ndebele',
            },
            {
                code: 'ne',
                name: 'Nepali',
            },
            {
                code: 'ng',
                name: 'Ndonga',
            },
            {
                code: 'nn',
                name: 'Norwegian Nynorsk',
            },
            {
                code: 'no',
                name: 'Norwegian',
            },
            {
                code: 'ii',
                name: 'Nuosu',
            },
            {
                code: 'nr',
                name: 'South Ndebele',
            },
            {
                code: 'oc',
                name: 'Occitan',
            },
            {
                code: 'oj',
                name: 'Ojibwe',
            },
            {
                code: 'cu',
                name: 'Old Church Slavonic',
            },
            {
                code: 'om',
                name: 'Oromo',
            },
            {
                code: 'or',
                name: 'Oriya',
            },
            {
                code: 'os',
                name: 'Ossetian',
            },
            {
                code: 'pa',
                name: 'Panjabi',
            },
            {
                code: 'pi',
                name: 'PÄli',
            },
            {
                code: 'fa',
                name: 'Persian',
            },
            {
                code: 'pl',
                name: 'Polish',
            },
            {
                code: 'ps',
                name: 'Pashto',
            },
            {
                code: 'pt',
                name: 'Portuguese',
            },
            {
                code: 'qu',
                name: 'Quechua',
            },
            {
                code: 'rm',
                name: 'Romansh',
            },
            {
                code: 'rn',
                name: 'Kirundi',
            },
            {
                code: 'ro',
                name: 'Romanian',
            },
            {
                code: 'ru',
                name: 'Russian',
            },
            {
                code: 'sa',
                name: 'Sanskrit',
            },
            {
                code: 'sc',
                name: 'Sardinian',
            },
            {
                code: 'sd',
                name: 'Sindhi',
            },
            {
                code: 'se',
                name: 'Northern Sami',
            },
            {
                code: 'sm',
                name: 'Samoan',
            },
            {
                code: 'sg',
                name: 'Sango',
            },
            {
                code: 'sr',
                name: 'Serbian',
            },
            {
                code: 'gd',
                name: 'Scottish Gaelic',
            },
            {
                code: 'sn',
                name: 'Shona',
            },
            {
                code: 'si',
                name: 'Sinhala',
            },
            {
                code: 'sk',
                name: 'Slovak',
            },
            {
                code: 'sl',
                name: 'Slovene',
            },
            {
                code: 'so',
                name: 'Somali',
            },
            {
                code: 'st',
                name: 'Southern Sotho',
            },
            {
                code: 'es',
                name: 'Spanish',
            },
            {
                code: 'su',
                name: 'Sundanese',
            },
            {
                code: 'sw',
                name: 'Swahili',
            },
            {
                code: 'ss',
                name: 'Swati',
            },
            {
                code: 'sv',
                name: 'Swedish',
            },
            {
                code: 'ta',
                name: 'Tamil',
            },
            {
                code: 'te',
                name: 'Telugu',
            },
            {
                code: 'tg',
                name: 'Tajik',
            },
            {
                code: 'th',
                name: 'Thai',
            },
            {
                code: 'ti',
                name: 'Tigrinya',
            },
            {
                code: 'bo',
                name: 'Tibetan Standard',
            },
            {
                code: 'tk',
                name: 'Turkmen',
            },
            {
                code: 'tl',
                name: 'Tagalog',
            },
            {
                code: 'tn',
                name: 'Tswana',
            },
            {
                code: 'to',
                name: 'Tonga',
            },
            {
                code: 'tr',
                name: 'Turkish',
            },
            {
                code: 'ts',
                name: 'Tsonga',
            },
            {
                code: 'tt',
                name: 'Tatar',
            },
            {
                code: 'tw',
                name: 'Twi',
            },
            {
                code: 'ty',
                name: 'Tahitian',
            },
            {
                code: 'ug',
                name: 'Uyghur',
            },
            {
                code: 'uk',
                name: 'Ukrainian',
            },
            {
                code: 'ur',
                name: 'Urdu',
            },
            {
                code: 'uz',
                name: 'Uzbek',
            },
            {
                code: 've',
                name: 'Venda',
            },
            {
                code: 'vi',
                name: 'Vietnamese',
            },
            {
                code: 'vo',
                name: 'VolapÃ¼k',
            },
            {
                code: 'wa',
                name: 'Walloon',
            },
            {
                code: 'cy',
                name: 'Welsh',
            },
            {
                code: 'wo',
                name: 'Wolof',
            },
            {
                code: 'fy',
                name: 'Western Frisian',
            },
            {
                code: 'xh',
                name: 'Xhosa',
            },
            {
                code: 'yi',
                name: 'Yiddish',
            },
            {
                code: 'yo',
                name: 'Yoruba',
            },
            {
                code: 'za',
                name: 'Zhuang',
            },
            {
                code: 'zu',
                name: 'Zulu',
            },
        ];
    }

    arrToDropdown(list: string[] | number[]) {
        let newList: DropdownList[] = [];
        list.forEach((item) =>
            newList.push({
                value: item,
                text: String(item),
            })
        );
        return newList;
    }

    dropdownToArrSTR(list: DropdownList[]): string[] {
        let newList: string[] = [];
        list.forEach((item) => newList.push(String(item.value) ?? ''));
        return newList;
    }

    dropdownToArrNUM(list: DropdownList[]): number[] {
        let newList: number[] = [];
        list.forEach((item) => newList.push(Number(item.value) ?? 0));
        return newList;
    }

    getValueDurationPicker(time: string) {
        const substr1 = time.substring(0, 2);
        const substr2 = time.slice(2, 4);
        let value = (+substr1 * 60 + +substr2) * 60000;
        return value;
    }

    setValueDurationPicker(ms: number) {
        var hours = ms / (1000 * 60 * 60);
        var absoluteHours = Math.floor(hours);

        //Get remainder from hours and convert to minutes
        var m = (ms / (1000 * 60)) % 60;
        // var minutes = (hours - absoluteHours) * 60;
        // var absoluteMinutes = Math.floor(minutes);
        // var m = absoluteMinutes > 9 ? absoluteMinutes : '0' + absoluteMinutes;
        let hoursStr: string | number = '';
        let minStr: string | number = '';
        if (absoluteHours < 10) {
            hoursStr = '0' + absoluteHours;
        } else {
            hoursStr = absoluteHours;
        }
        if (m < 10) {
            minStr = '0' + m;
        } else {
            minStr = m;
        }
        return `${hoursStr}:${minStr}`;
    }

    numberWithCommas(num: number | string | undefined | null) {
        if (num) {
            if (Number.isNaN(num)) return '0';
            else return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        }
        return '0';
    }

    numberWithoutCommas(num: string | Number | undefined | null): number {
        let result: number = 0;
        if (num) {
            result = Number(String(num).replace(/\,/g, ''));
            if (Number.isNaN(result)) return 0;
            else return result;
        }
        return 0;
    }

    elementHighlight(el: any) {
        // { behavior: 'smooth' }
        el.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'start' });
        el.focus();
        el.classList.add("input-foucsed");
        setTimeout(() => {
            el?.classList.remove("input-foucsed");
        }, 2000);
    }

    getCookie(cookieName: string) {
        var name = cookieName + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var cookieArray = decodedCookie.split(";");

        for (var i = 0; i < cookieArray.length; i++) {
            var cookie = cookieArray[i].trim();

            if (cookie.indexOf(name) === 0) {
                return cookie.substring(name.length, cookie.length);
            }
        }

        return "";
    }

}

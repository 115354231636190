import { ApiResponse, PagingData, ApiResponseAny, PutApiResponse } from './../../core/models/api-response.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { TcNotification, NotificationCount, NotificationReadTypeEnum, RegisterNotification, GetDataOptions } from '../model/shared.model';
import { lastValueFrom } from 'rxjs';

const APIURL = `${environment.apiUrl}/usr/user/`;
const APIURL2 = `${environment.apiUrl}/`;

export interface AlertSnooze {
    userAlertId: number
    snoozedForDate: string
}

@Injectable({
    providedIn: 'root'
})
export class NotificationsApiService {

    requestOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'json' }), responseType: 'json' as 'json' };

    constructor(
        private http: HttpClient,
    ) { }

    async notificationsTop(userId: number, notificationReadType: NotificationReadTypeEnum, count: number): Promise<ApiResponse<PagingData<TcNotification>>> {
        let response = await lastValueFrom(this.http.get<ApiResponse<PagingData<TcNotification>>>(APIURL + `${userId}/WebAlert/FpList?&alertReadType=${notificationReadType}&PageSize=${count}&PageIndex=0`));
        if (response.success) {
            return response;
        } else {
            return {
                success: false,
                data: null
            }
        }
    }

    async notificationsCount(userId: number, companyId: number, notificationReadType: NotificationReadTypeEnum): Promise<ApiResponse<PagingData<NotificationCount>>> {
        let response = await lastValueFrom(this.http.get<ApiResponse<PagingData<NotificationCount>>>(APIURL + `${userId}/WebAlert/Count?companyId=${companyId}&alertReadType=${notificationReadType}`));
        if (response.success) {
            return response;
        }
        else {
            return {
                success: false,
                data: null
            }
        }
    }

    async markAsRead(userId: number, notificationId: number, body: {}): Promise<ApiResponseAny> {
        let response = await lastValueFrom(this.http
            .put<ApiResponseAny>(
                APIURL + `${userId}/Alert/${notificationId}`, body,
                this.requestOptions
            ));
        if (response.success) {
            return response;
        }
        else {
            return {
                success: false,
                data: null
            }
        }
    }

    // async registerNotification(body: RegisterNotification): Promise<ApiResponse<RegisterNotification[]>> {
    //     let response = await lastValueFrom(this.http
    //         .post<ApiResponse<RegisterNotification[]>>(
    //             APIURL2 + `cmp/Notification/Register`, body,
    //             this.requestOptions
    //         )
    //);
    //     if (response.success) {
    //         return response;
    //     }else{
    //         return {
    //             success: false,
    //             data: []
    //         };
    //     }
    // }


    // needsModification
    async allNotification(userId: number, options: GetDataOptions, notificationReadType: NotificationReadTypeEnum): Promise<ApiResponse<PagingData<TcNotification>>> {
        let response = await lastValueFrom(this.http.get<ApiResponse<PagingData<TcNotification>>>(APIURL + `${userId}/WebAlert/FpList?searchString=${options.searchString}&pageIndex=${options.pageIndex}&pageSize=${options.pageSize}&alertReadType=${notificationReadType}`));
        if (response.success) {
            return response;
        }
        else {
            return {
                success: false,
                data: null
            }
        }


    }

    async getNotificationById(userId: number, notificationId: number): Promise<ApiResponse<TcNotification>> {
        let response = await lastValueFrom(this.http.get<ApiResponse<TcNotification>>(APIURL + `${userId}/Alert/${notificationId}`));

        if (response.success) {
            return response;
        }
        else {
            return {
                success: false,
                data: null
            }
        }
    }

    async alertSnooze(body: AlertSnooze): Promise<PutApiResponse> {
        let response = await lastValueFrom(this.http
            .put<PutApiResponse>(
                APIURL + `Alert/Snooze`, body,
                this.requestOptions
            ));
        if (response.success) {
            return response;
        }
        else {
            return {
                success: false
            }
        }
    }

    
}

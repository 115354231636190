export enum PermissionResourcesEnum {

    // Users = 'Users',
    Drivers = 'client--human-resource--driver',
    Employees = 'client--human-resource--office',
    JobOpening = 'client--recruiting--job-opening',
    Trucks = 'client--asset--truck',
    Trailers = 'client--asset--trailer',
    Providers = 'client--support-service--provider',
    Incidents = 'client--safety--incident',
    Inspections = 'client--safety--inspection',
    DrugandAlcoholTestSettings = 'client--settings--drug-n-alcohol-test',
    DrugandAlcoholTests = 'client--safety--drug-n-alcohol-test',
    Permits = 'client--permit--permit-setup',
    PermitFiling = 'client--permit--filing',
    InsurancePolicies = 'client--insurance--insurance-policy',
    Endorsements = 'client--insurance--endorsement',
    Claims = 'client--insurance--claim',
    InsuranceRenewals = 'client--insurance--insurance-renewal',
    Pairings = 'client--operation--pairing',
    RolesPermissions = 'client--administration--role-n-permission',
    Companies = 'client--administration--company-information',
    OutgoingEmail = 'client--settings--outgoing-email',
    Notifications = 'client--settings--notification',
    Integrations = 'client--settings--integration',
    GeneralSettings = 'client--settings--general',
    TruckBinders = 'client--settings--truck-binder',
    Billing = 'client--settings--billing',
    Repairs = 'client--repairs-n-maintenances--repairs',
    MaintenanceSchedules = 'client--repairs-n-maintenances--maintenance-schedules',
    //Policies = 'Policies',
    FuelCards = 'system--fuel-card--fuel-card-promotion',
    SystemRolesPermissions = 'system--administration--role-n-permission',
    AccountRequests = 'system--sales--account-request',
    PricingHistory = 'system--fuel-card--pricing-history',
    Subscriptions = 'system--fuel-card--subscription',
    Blogs = 'system--administration--blog',
    EldMonitoring = 'client--dashboard--eld-monitoring',
    BackgroundScreeningOrders = 'client--background-screening--orders'
}

export enum PermissionsEnum {
    Add = 'Add',
    List = 'List',
    Edit = 'Edit',
    View = 'View',
    Delete = 'Delete',
    Reports = 'Reports',
    // Custom
    SubList = 'SubList',
    SubView = 'SubView',
}

export interface UserPermissions {
    permissionShaHash: string;
    userResourcesPermissions: {
        name: PermissionResourcesEnum;
        permissions: {
            name: PermissionsEnum;
        }[];
    }[];
}

export interface PermissionsGranted {
    isEditGranted: boolean;
    isViewGranted?: boolean;
    isDeleteGranted?: boolean;
}

export interface PermissionsGrantedList {
    isEditGranted?: boolean;
    isViewGranted?: boolean;
    isAddGranted: boolean;
    isDeleteGranted?: boolean;
}

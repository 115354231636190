import { ContextService } from 'src/app/shared/services/context.service';
import { SharedService } from './shared.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import {
    TcNotification,
    NotificationReadTypeEnum,
    NotificationTypeEnum,
} from './../model/shared.model';
import { Injectable, OnDestroy } from '@angular/core';
import { NotificationsApiService } from './notifications-api.service';
import {
    deleteToken,
    getMessaging,
    getToken,
    onMessage,
} from 'firebase/messaging';
import { AlertTypeEnum } from 'src/app/core/models/shared.model';
import { EmployeeCompany } from 'src/app/core/models/company.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'primeng/api';
const DEVICE_ID = 'device-id';
const NOTOFICATIONS_TOKEN = 'notifications-token';
const NOTOFICATIONS_PREF = 'notifications-preference';
//
const USER_KEY = 'auth-user-id';
const COMPANY_LOCATION_KEY = 'company-location-id';

@Injectable({
    providedIn: 'root',
})
export class NotificationsService implements OnDestroy {
    notificationsCount$ = new BehaviorSubject<number>(0);
    totalCompanyNotificationCount$ = new BehaviorSubject<number>(0);
    companyIdSub: Subscription | null = null;
    userCompanies: any[] = [];
    userId: number = 0;
    loggedInUserSub: Subscription | null = null;

    constructor(
        private notificationsApiService: NotificationsApiService,
        private router: Router,
        private sharedService: SharedService,
        private context: ContextService,
        private spinner: NgxSpinnerService,
        private messageService: MessageService
    ) {
        this.context.getUserId();
    }

    public async updateNotifications() {
        let userId = this.context.getUserId();
        let companyId = this.context.getCompanyId();
        let result: number = 0;
        if (userId > 0 && companyId > 0)
            result = await this.getNotificationsCount(userId, companyId);
        this.notificationsCount$.next(+result);
        this.setOtherLocationNotificationsCount(
            this.context.employeeCompanies$.getValue(),
            userId
        );
    }

    setDeviceId(id: string) {
        window.localStorage.setItem(DEVICE_ID, id);
    }

    getDeviceId() {
        return window.localStorage.getItem(DEVICE_ID);
    }

    setFirebaseNotificationPermission(permission: NotificationPermission) {
        if (permission == 'granted') {
            window.localStorage.setItem(NOTOFICATIONS_PREF, permission);
        } else if (permission == 'denied' || permission == 'default') {
            window.localStorage.setItem(NOTOFICATIONS_PREF, permission);
            this.setFirebaseNotificationToken('');
        }
    }

    getFirebaseNotificationPermission(): NotificationPermission {
        return (window.localStorage.getItem(NOTOFICATIONS_PREF) ??
            'default') as NotificationPermission;
    }

    setFirebaseNotificationToken(token: string) {
        if (token == '') window.localStorage.removeItem(NOTOFICATIONS_TOKEN);
        else window.localStorage.setItem(NOTOFICATIONS_TOKEN, token);
    }

    getFirebaseNotificationToken() {
        return window.localStorage.getItem(NOTOFICATIONS_TOKEN);
    }

    async getNotificationsCount(
        userId: number,
        companyId: number,
        notificationReadType = NotificationReadTypeEnum.Unread
    ) {
        try {
            let result = await this.notificationsApiService.notificationsCount(
                userId,
                companyId,
                notificationReadType
            );
            if (result.success && result.data) {
                return result.data.count;
            }
            return 0;
        } catch (error: any) {
            return 0;
        }
    }

    async markAsRead(userId: number, notificationId: number, isRead: boolean) {
        let body = {
            id: 0,
            isRead: isRead,
        };
        try {
            let response = await this.notificationsApiService.markAsRead(
                userId,
                notificationId,
                body
            );
            if (response.success) {
                this.setOtherLocationNotificationsCount(
                    this.userCompanies,
                    this.userId
                );
            }
            return response;
        } catch (error: any) {
            return {
                success: false,
            };
        }
    }

    ///////////////////////////////////////////////

    async getFirebaseToken(): Promise<string | null> {
        const messaging = getMessaging();
        try {
            let currentToken = await getToken(messaging, {
                vapidKey: environment.firebase.vapidKey,
            });
            if (currentToken) {
                this.setFirebaseNotificationToken(currentToken);
                return currentToken;
            } else {
                console.log(
                    'No registration token available. Request permission to generate one.'
                );
                return null;
            }
        } catch (err: any) {
            console.log('An error occurred while retrieving token. ', err);
            return null;
        }
    }

    listen() {
        const messaging = getMessaging();
        onMessage(messaging, async (payload) => {
            // let alert =
            //     "<div class='notification-tost-message'><a href='" +
            //     environment.webUrl +
            //     '/notifications/notification-handler/' +
            //     payload?.data?.Id +
            //     "'><h6 class='heading-6'>" +
            //     payload.notification?.title +
            //     "</h6><p class='body-short-2'>" +
            //     payload.notification?.body +
            //     '</p></a></div>';
            // this.sharedService.alert(alert, AlertTypeEnum.Info);
            this.messageService.add({
                key: 'notification',
                severity: 'info',
                summary: payload.notification?.title,
                detail: payload.notification?.body,
                icon: 'icon-bell-01',
                data: { notificationObj: payload?.data },
            });
            this.updateNotifications();
        });
    }

    async setOtherLocationNotificationsCount(
        userCompanies: EmployeeCompany[],
        userId: number
    ) {
        this.userCompanies = userCompanies;
        this.userId = userId;
        let companyNotificationCount = 0;
        userCompanies.forEach(async (company) => {
            let count = await this.getNotificationsCount(userId, company.id);
            companyNotificationCount = companyNotificationCount + count;
            // location.numberOfNotifications = count;
            company.numberOfNotifications = count;
            this.totalCompanyNotificationCount$.next(companyNotificationCount);
        });
        return userCompanies;
    }

    // async registerNotification() {
    //     let body: RegisterNotification = {
    //         id: 0,
    //         deviceId: this.getDeviceId(),
    //         deviceType: 'WEB',
    //         notificationType: 'FCM',
    //         notificationToken: this.getFirebaseNotificationToken(),
    //     };
    //     try {
    //         let response =
    //             await this.notificationsApiService.registerNotification(body);
    //         if (response.success && response.data) {
    //             this.setDeviceId(response.data['deviceId']);
    //         }
    //     } catch (err: any) {}
    // }

    getNotificationIcon(type: NotificationTypeEnum) {
        return 'icon-bell-04 icon-lg';
    }

    getLink(
        objectType: string,
        objectData: string,
        notificationType: NotificationTypeEnum | string,
        objectId: number
    ) {
        let link: string = '';
        let data = objectData ? JSON.parse(objectData) : null;
        switch (objectType) {
            case 'AssetTruck':
                switch (notificationType) {
                    case 'client--asset--truck--status-change':
                    case 'client--asset--truck--annual-inspection-document-expiration-date':
                    case 'client--asset--truck--not-covered-in-auto-liability':
                        link = `/trucks/truck/${objectId}/view/basic-information`;
                        break;
                    case 'client--asset--truck--form-2290-expiration-date':
                        link = `/trucks`;
                        break;
                    case 'client--asset--truck--irp-registration-cab-card-expiration-date':
                        link = `/trucks/truck/${objectId}/view/licenses-and-permits`;
                        break;
                    case 'client--asset--truck--other-documents-is-expiring':
                        link = `/trucks/truck/${objectId}/view/other-documents`;
                        break;
                    default:
                        link = `/trucks/truck/${objectId}/view/overview`;
                }
                break;
            case 'AssetTrailer':
                switch (notificationType) {
                    case 'client--asset--trailer--status-change':
                    case 'client--asset--trailer--annual-inspection-document-expiration-date':
                    case 'client--asset--trailer--not-covered-in-auto-liability':
                        link = `/trailers/trailer/${objectId}/view/basic-information`;
                        break;
                    case 'client--asset--trailer--irp-registration-cab-card-expiration-date':
                        link = `/trailers/trailer/${objectId}/view/registration`;
                        break;
                    case 'client--asset--trailer--other-documents-is-expiring':
                        link = `/trailers/trailer/${objectId}/view/other-documents`;
                        break;
                    default:
                        link = `/trailers/trailer/${objectId}/view/overview`;
                }
                break;
            case 'MaintenanceScheduleEnrolledUnit':
                switch (notificationType) {
                    case 'client--repairs-n-maintenances--maintenance-schedules--asset-enrolled-in-maintenance-schedule':
                        link = `/maintenance-schedules/schedule/${objectId}/view/enrolled-units`;
                        break;
                    case 'client--repairs-n-maintenances--maintenance-schedules--asset-due-for-maintenance':
                        link = `/maintenance-schedules/schedule/${objectId}/view/basic-information`;
                        break;
                    default:
                        link = `/maintenance-schedules/schedule/${objectId}/view/basic-information`;
                }
                break;
            case 'Repair':
                switch (notificationType) {
                    case 'client--repairs-n-maintenances--repairs--repair-request':
                    case 'client--repairs-n-maintenances--repairs--repair-status-is-changed':
                    default:
                        link = `repairs/repair/${objectId}/view/basic-information`;
                        break;
                }
                break;
            case 'Company':
                switch (notificationType) {
                    case 'client--administration--company-information--mcs150b-is-due-for-submission':
                    case 'client--administration--company-information--ucr-is-expiring':
                    case 'client--administration--company-information--scac-code-is-expiring':
                        link = `company/licenses-registration`;
                        break;
                    case 'client--administration--company-information--document-in-other-document-is-expiring':
                        link = `company/other-documents`;
                        break;
                    default:
                        link = `company/basic-information`;
                }
                break;
            case 'RolesNPermission':
                switch (notificationType) {
                    case 'client--administration--role-n-permission--new-user-is-added':
                        link = `roles/${objectId}/members`;
                        break;
                    default:
                        link = `roles/1/role-details`;
                }
                break;
            case 'Driver':
                switch (notificationType) {
                    case 'client--human-resource--driver--drivers-status-change':
                    case 'client--human-resource--driver--medical-card-expiration-date':
                        link = `driver/${objectId}/view/personal-info`;
                        break;
                    case 'client--human-resource--driver--cdl-expiration-date':
                        link = `driver/${objectId}/view/cdl`;
                        break;
                    case 'client--human-resource--driver--last-annual-review-date-exceeds-12-months':
                        link = `driver/${objectId}/view/annual-review-list`;
                        break;
                    default:
                        link = `driver/${objectId}/view/personal-info`;
                }
                break;
            case 'Office':
                switch (notificationType) {
                    case 'client--human-resource--office--employees-status-change':
                    default:
                        link = `employees/${objectId}/view/employee-info`;
                }
                break;
            case 'Permit':
                switch (notificationType) {
                    case 'client--permit--permit-setup--ifta-license-is-expiring':
                    case 'client--permit--permit-setup--uploading-ifta-license':
                        link = `permit-setup/permits/view/ifta`;
                        break;
                    case 'client--permit--permit-setup--ny-hut-license-is-expiring':
                    case 'client--permit--permit-setup--uploading-nyhut-license':
                        link = `permit-setup/permits/view/ny-hut`;
                        break;
                    case 'client--permit--permit-setup--kyu-license-is-expiring':
                    case 'client--permit--permit-setup--or-license-is-expiring':
                    case 'client--permit--permit-setup--nm-license-is-expiring':
                        link = `permit-setup/permits/view/other-permits`;
                        break;
                }
                break;
            case 'PermitFiling':
                switch (notificationType) {
                    case 'client--permit--filing--filing-is-due':
                    default:
                        link = `filing/overview`;
                }
                break;
            case 'Inspection':
                switch (notificationType) {
                    case 'client--safety--inspection--new-inspections-notification':
                    case 'client--safety--inspection--inspection-reporting-to-authority-alerts':
                    default:
                        link = `inspection/${objectId}/view/inspection-report`;
                        break;
                }
                break;
            case 'Incident':
                switch (notificationType) {
                    case 'client--safety--incident--driver--start-reporting-an-incident':
                        link = `incidents/incidents-list`;
                        break;
                    case 'client--safety--incident--driver-reports-an-incident':
                    case 'client--safety--incident--requests-an-incident-entry-removal':
                        link = `incidents/edit-incident/${objectId}/view/basic-information`;
                        break;
                }
                break;
            case 'RandomDrugTest':
                switch (notificationType) {
                    case 'client--safety--drug-n-alcohol-test--driver-does-not-acknowledge-random-test':
                    case 'client--safety--drug-n-alcohol-test--driver-acknowledges-random-test':
                    case 'client--safety--drug-n-alcohol-test--random-test-is-not-taken-within-specified-time-window':
                    case 'client--safety--drug-n-alcohol-test--when-driver-uploads-the-random-test-receipt':
                    case 'client--safety--drug-n-alcohol-test--random-test-result-received-through-integration-with-third-party':
                    case 'client--safety--drug-n-alcohol-test--random-test-cycle-about-to-expire-and-not-all-drivers-taken-the-test':
                    case 'client--safety--drug-n-alcohol-test--random-test-cycle-about-to-expire-and-test-status-still-open':
                        link = `drug-and-alcohol-tests/view/${objectId}/edit-random`;
                        break;
                }
                break;
            case 'suspicionDrugTest':
                switch (notificationType) {
                    case 'client--safety--drug-n-alcohol-test--suspicion-test-delayed-for-8-hours':
                    case 'client--safety--drug-n-alcohol-test--reasonable-suspicion-24-hours-delation-end':
                    case 'client--safety--drug-n-alcohol-test--suspicion-test-is-not-taken-within-specified-time-window':
                    case 'client--safety--drug-n-alcohol-test--suspicion-test-result-received-through-integration-with-third-party':
                        link = `drug-and-alcohol-tests/view/${objectId}/edit-reasonable-suspicion-test`;
                }
                break;
            case 'PostAccidentDrugTest':
                switch (notificationType) {
                    case 'client--safety--drug-n-alcohol-test--driver-does-not-acknowledge-post-accident-test':
                    case 'client--safety--drug-n-alcohol-test--driver-acknowledges-post-accident-test':
                    case 'client--safety--drug-n-alcohol-test--post-accident-test-is-not-taken-within-specified-time-window':
                    case 'client--safety--drug-n-alcohol-test--when-driver-uploads-the-post-accident-test-receipt':
                    case 'client--safety--drug-n-alcohol-test--post-accident-test-result-received-through-integration-with-third-party':
                    case 'client--safety--drug-n-alcohol-test--post-accident-test-delayed-for-8-hours':
                    case 'client--safety--drug-n-alcohol-test--post-accident-test-delayed-for-32-hours':
                        link = `drug-and-alcohol-tests/view/${objectId}/edit-post-accident-test`;
                        break;
                }
                break;
            case 'ReturnToDutyDrugTest':
                switch (notificationType) {
                    case 'client--safety--drug-n-alcohol-test--driver-does-not-acknowledge-return-to-duty-test':
                    case 'client--safety--drug-n-alcohol-test--driver-acknowledges-return-to-duty-test':
                    case 'client--safety--drug-n-alcohol-test--return-to-duty-test-is-not-taken-within-specified-time-window':
                    case 'client--safety--drug-n-alcohol-test--when-driver-uploads-the-return-to-duty-test-receipt':
                    case 'client--safety--drug-n-alcohol-test--return-to-duty-test-result-received-through-integration-with-third-party':
                        link = `drug-and-alcohol-tests/view/${objectId}/edit-return-to-duty-test`;
                }
                break;
            case 'Supplier':
                switch (notificationType) {
                    case 'client--support-service--provider--provider-status-is-changed':
                    default:
                        link = `procurement/suppliers/supplier/${objectId}/view/basic-information`;
                }
                break;
            case 'InsurancePolicy':
                switch (notificationType) {
                    case 'client--insurance--insurance-policy--insurance-policy-is-expiring':
                        link = `insurance/insurance-policy/${objectId}/view/basic-information`;
                        break;
                }
                break;
            case 'InsuranceRenewal':
                switch (notificationType) {
                    case 'client--insurance--insurance-renewal--renewal-is-created':
                        link = `insurance-renewals/insurance/${objectId}/view/insurance-comp-information`;
                        break;
                }
                break;
            case 'Endorsement':
                switch (notificationType) {
                    case 'client--insurance--endorsement--endorsement-submitted-to-insurance-agency':
                    case 'client--insurance--endorsement--endorsement-status-is-changed-to-closed':
                    case 'client--insurance--endorsement--endorsement-change-request-requires-update-information':
                        link = `endorsements/edit-endorsement/${objectId}`;
                        break;
                }
                break;
            case 'JobOpeningApplication':
                switch (notificationType) {
                    case 'client--recruiting--job-opening--applicant-withdraw-his-application':
                        link = `recruitment/job-openings/job/${data.jobOpeningId}/view/applications`;
                        break;
                    case 'client--recruiting--job-opening--applicant-successfully-submits-his-specimen':
                        link = `recruitment/job-openings/job/${data.jobOpeningId}/application-process/${objectId}/view/testing/drug-alcohol-test`;
                        break;
                    case 'client--recruiting--job-opening--psp-report-is-received':
                        link = `recruitment/job-openings/job/${data.jobOpeningId}/application-process/${objectId}/view/investigation/psp`;
                        break;
                    case 'client--recruiting--job-opening--mvr-report-is-received':
                        link = `recruitment/job-openings/job/${data.jobOpeningId}/application-process/${objectId}/view/investigation/mvr`;
                        break;
                    case 'client--recruiting--job-opening--previous-employer-submits-safety-history':
                        link = `recruitment/job-openings/job/${data.jobOpeningId}/application-process/${objectId}/view/investigation/previous-employers`;
                        break;
                    case 'client--recruiting--job-opening--applicant-respond-to-update-information-request':
                    case 'client--recruiting--job-opening--applicant-submits-application-to-job-opening':
                        link = `recruitment/job-openings/job/${data.jobOpeningId}/application-process/${objectId}/view/review/applicant-personal`;
                        break;
                    case 'client--recruiting--job-opening--applicant-responds-to-offer':
                        link = `recruitment/job-openings/job/${data.jobOpeningId}/application-process/${objectId}/view/job-offer/job`;
                        break;
                    case 'client--recruiting--job-opening--number-of-hired-drivers-for-job-opening-meets-the-requirement':
                        link = `recruitment/job-openings/job/${data.jobOpeningId}/view/overview`;
                        break;
                }
                break;
            case 'PairingRequest':
                switch (notificationType) {
                    case 'client--operation--pairing--Pairing-Request-Received':
                        link = `pairing/pairing-requests`;
                }
                break;
            case 'Invoice':
                // switch (notificationType) {
                // case 'client--settings--billing-invoice-payment-reminder':
                link = `settings/billing/invoices`;
                // }
                break;
            case 'CarrierApplication':
                switch (notificationType) {
                    case 'system--sales--account-request--subscription-cancelation-request':
                        link = `account-requests/request/${objectId}/view/account-details`;
                }
                break;
            //****************** Old Notifications ******************//
            // case 'JobOpeningApplication':
            //     link = `/recruitment/job-openings/job/${data.JobOpeningId}/application-process/${data.jobOpeningApplicationId}/view/review/applicant-personal`;
            //     break;
            case 'PreviousEmployerfeedback':
                link = `/recruitment/job-openings/job/${data.JobOpeningId}/application-process/${data.jobOpeningApplicationId}/view/investigation/previous-employers`;
                break;
            case 'DrugTest':
                if (
                    notificationType ==
                    NotificationTypeEnum.ReasonableSuspicion24HoursDelation
                ) {
                    link = `/driver/${data.DrugAlcoholTestDriverId}/view/personal-info`;
                } else {
                    link = `/recruitment/job-openings/job/${data.JobOpeningId}/application-process/${data.jobOpeningApplicationId}/view/testing/drug-alcohol-test`;
                }
                break;
            case 'Hiring':
                if (notificationType == NotificationTypeEnum.HiringRejected) {
                    link = `/recruitment/job-openings/job/${data.JobOpeningId}/application-process/${data.jobOpeningApplicationId}/view/review/applicant-personal`;
                } else {
                    link = `/recruitment/job-openings/job/${data.JobOpeningId}/application-process/${data.jobOpeningApplicationId}/view/job-offer/job`;
                }
                break;
            // case 'Incident':
            //     if (notificationType == NotificationTypeEnum.RemoveIncident) {
            //         link = `/incidents/edit-incident/${data.IncidentId}/view/basic-information?remove=true`;
            //     } else {
            //         link = `/incidents/edit-incident/${data.IncidentId}/view/basic-information`;
            //     }
            //     break;
            // case 'RandomDrugTest':
            //     link = `/drug-and-alcohol-tests/view/${data.drugAlcoholTestId}/edit-random/${data.DrugAlcoholTestDriverId}`;
            //     break;
            // case 'ReturnToDutyDrugTest':
            //     link = `/drug-and-alcohol-tests/view/${data.drugAlcoholTestId}/edit-return-to-duty-test`;
            //     break;
            // case 'PostAccidentDrugTest':
            //     link = `/drug-and-alcohol-tests/view/${data.drugAlcoholTestId}/edit-post-accident-test`;
            //     break;
            // case 'Endorsement':
            //     link = `/endorsements/edit-endorsement/${data.EndorsementId}`;
            //     break;
            case 'JobOpening':
                link = `recruitment/job-openings/job/${data.JobOpeningId}/edit/job-details`;
                break;
            // case 'Company':
            //     link = `account-requests/${data.CarrierApplicationId}/view/account-details`;
            //     break;
            // case 'Pairing':
            //     switch (notificationType) {
            //         case NotificationTypeEnum.PairingRequestReceived:
            //             link = `pairing/pairing-requests`;
            //             break;
            //         default:
            //             link = `pairing/overview`;
            //     }
            //     break;
            case 'AnnualReview':
                switch (notificationType) {
                    case NotificationTypeEnum.AnnualReviewIsDue:
                        link = `driver/${data.EmployeeId}/view/annual-review-list`;
                        break;
                    default:
                        link = `driver/${data.EmployeeId}/view/annual-review-list`;
                }
                break;
            case 'TruckBinderSettings':
                link = `settings/truck-binder`;
                break;
            // case 'Driver':
            //     link = `driver/${data.Id}/view/overview`;
            //     break;
            case 'MaintenanceIsDue':
                link = `repairs/add-repair/enrolled-unit/${data.EnrolledUnitId}`;
                break;
            case 'RepairRequest':
                link = `repairs/repair/${data.RepairId}/view/basic-information`;
                break;
            default:
                link = '/home';
        }
        return link;
    }

    goToLink(notification: TcNotification) {
        let link = this.getLink(
            notification.objectType,
            notification.data,
            notification.alertType,
            notification.objectId
        );
        if (link) {
            window.location.href = link;
            return link;
        }
        return null;
        // this.router.navigateByUrl('/home', { skipLocationChange: true })
        //     .then(() => this.router.navigateByUrl(
        //         this.getLink(
        //             notification.objectType,
        //             notification.data,
        //             notification.alertType
        //         )
        //     )
        //     )
    }

    ngOnDestroy(): void {
        if (this.loggedInUserSub) this.loggedInUserSub.unsubscribe();
        if (this.companyIdSub) this.companyIdSub.unsubscribe();
    }
}

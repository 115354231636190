import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject, lastValueFrom } from 'rxjs';
import { ApiResponse } from 'src/app/core/models/api-response.model';
import { environment } from 'src/environments/environment';
import {
    PermissionResourcesEnum,
    PermissionsEnum,
    UserPermissions,
} from '../model/permissions.model';
import { DateService } from './date.service';;
import { tcRequireCompany } from '../../core/interceptors/auth.interceptor';
const PERMISSIONS_HASH_KEY = 'user-permissions-hash';
const PERMISSIONS_KEY = 'user-permissions';
const APIURL = `${environment.apiUrl}/usr/Authorize/Permission/`;
const APISYSURL = `${environment.apiUrl}/sys/Authorize/Permission/`;
const ONE_HOUR_PERMISSIONS_KEY = 'one-hour-permissions';
const HOUR_IN_MS = 3600000;

@Injectable({
    providedIn: 'root',
})
export class PermissionsService {
    PermissionsList$: Subject<UserPermissions | null> = new Subject();
    permissionsListFromLocal = localStorage.getItem(PERMISSIONS_KEY)
        ? JSON.parse(localStorage.getItem(PERMISSIONS_KEY) ?? '')
        : null;
    PermissionsList: UserPermissions | null = this.permissionsListFromLocal;
    _bypass: boolean = environment.permissionBypass;
    oneHourInterval: any;
    oneHourPermissions: {
        dateCreated: string;
        resourceName: PermissionResourcesEnum;
        objectId: number;
    }[] = window.sessionStorage.getItem(ONE_HOUR_PERMISSIONS_KEY)
            ? JSON.parse(
                window.sessionStorage.getItem(ONE_HOUR_PERMISSIONS_KEY) ?? ''
            )
            : [];

    constructor(
        private http: HttpClient,
        private dateService: DateService
    ) { }

    set bypass(value: boolean) {
        this._bypass = value;
    }

    async getAllUserPermissions(
        castData: boolean = true
    ): Promise<UserPermissions | null> {
        clearInterval(this.oneHourInterval);
        this.manageInterval();
        try {
            const hash = localStorage.getItem(PERMISSIONS_HASH_KEY) ?? '';
            const response = window.localStorage.getItem('system-admin') == 'true' ?
            await lastValueFrom(this.http
                .get<ApiResponse<UserPermissions>>(
                    `${APISYSURL}FpList`,
                    { context: tcRequireCompany(false) }
                )) :
            await lastValueFrom(this.http
                .get<ApiResponse<UserPermissions>>(
                    `${APIURL}FpList?PermissionShaHash=${hash}`
                ));
            if (response.data?.permissionShaHash === hash) {
                this.permissionsListFromLocal = localStorage.getItem(PERMISSIONS_KEY)
                    ? JSON.parse(localStorage.getItem(PERMISSIONS_KEY) ?? '')
                    : null;
                this.PermissionsList = this.permissionsListFromLocal;
                if (castData)
                    this.PermissionsList$.next(this.permissionsListFromLocal);
                return null;
            } else {
                this.resetPermissions();
                this.PermissionsList = response.data as UserPermissions;
                if (castData)
                    this.PermissionsList$.next(response.data as UserPermissions);
                localStorage.setItem(
                    PERMISSIONS_HASH_KEY,
                    response.data?.permissionShaHash ?? ''
                );
                localStorage.setItem(
                    PERMISSIONS_KEY,
                    JSON.stringify(response.data) ?? ''
                );
                return response.data as UserPermissions;
            }
        } catch (error: any) {
            console.log(error)
        } finally {
            return {
                permissionShaHash: '',
                userResourcesPermissions: []
            }
        }
    }

    isListGranted(resourceName: PermissionResourcesEnum) {
        if (this._bypass) {
            return true;
        }
        if (
            this.PermissionsList?.userResourcesPermissions
                .find((el) => el.name == resourceName)
                ?.permissions.find((p) => p.name == PermissionsEnum.List) ||
            this.isViewGranted(resourceName) ||
            this.isAddGranted(resourceName) ||
            this.isDeleteGranted(resourceName) ||
            this.isEditGranted(resourceName) || 
            this.isReportsGranted(resourceName)
        )
            return true;

        return false;
    }

    isAddGranted(resourceName: PermissionResourcesEnum) {
        if (this._bypass) {
            return true;
        }
        if (
            this.PermissionsList?.userResourcesPermissions
                .find((el) => el.name == resourceName)
                ?.permissions.find((p) => p.name == PermissionsEnum.Add)
        )
            return true;

        return false;
    }

    isEditGranted(
        resourceName: PermissionResourcesEnum,
        objectId: number | null = null
    ) {
        if (this._bypass) {
            return true;
        }
        if (
            this.PermissionsList?.userResourcesPermissions
                .find((el) => el.name == resourceName)
                ?.permissions.find((p) => p.name == PermissionsEnum.Edit)
        )
            return true;
        else {
            const grantedObject = this.oneHourPermissions.find(
                (o) => o.objectId == objectId && o.resourceName == resourceName
            );

            if (grantedObject) return true;
        }

        return false;
    }

    isViewGranted(resourceName: PermissionResourcesEnum) {
        if (this._bypass) {
            return true;
        }
        if (
            this.PermissionsList?.userResourcesPermissions
                .find((el) => el.name == resourceName)
                ?.permissions.find((p) => p.name == PermissionsEnum.View) ||
            this.isAddGranted(resourceName) ||
            this.isDeleteGranted(resourceName) ||
            this.isEditGranted(resourceName)
        )
            return true;

        return false;
    }

    isDeleteGranted(resourceName: PermissionResourcesEnum) {
        if (this._bypass) {
            return true;
        }

        if (
            this.PermissionsList?.userResourcesPermissions
                .find((el) => el.name == resourceName)
                ?.permissions.find((p) => p.name == PermissionsEnum.Delete)
        )
            return true;

        return false;
    }

    isReportsGranted(resourceName: PermissionResourcesEnum) {
        if (this._bypass) {
            return true;
        }
        if (
            this.PermissionsList?.userResourcesPermissions
                .find((el) => el.name == resourceName)
                ?.permissions.find((p) => p.name == PermissionsEnum.Reports)
        )
            return true;

        return false;
    }

    resetPermissions() {
        // this.PermissionsList = null;
        // this.PermissionsList$.next(null);
        localStorage.removeItem(PERMISSIONS_HASH_KEY);
        localStorage.removeItem(PERMISSIONS_KEY);
        this.oneHourPermissions = [];
        sessionStorage.clear();
    }

    // Sub Objects

    isSubListGranted(resourceName: PermissionResourcesEnum) {
        if (this._bypass) {
            return true;
        }
        if (
            this.isViewGranted(resourceName) ||
            this.isAddGranted(resourceName) ||
            this.isEditGranted(resourceName)
        )
            return true;

        return false;
    }

    isSubAddGranted(
        resourceName: PermissionResourcesEnum,
        objectId: number | null = null
    ) {
        if (this._bypass) {
            return true;
        }
        if (this.isEditGranted(resourceName, objectId)) return true;

        return false;
    }

    isSubEditGranted(
        resourceName: PermissionResourcesEnum,
        objectId: number | null = null
    ) {
        if (this._bypass) {
            return true;
        }
        if (this.isEditGranted(resourceName, objectId)) return true;

        return false;
    }

    isSubViewGranted(resourceName: PermissionResourcesEnum) {
        if (this._bypass) {
            return true;
        }
        if (
            this.isViewGranted(resourceName) ||
            this.isAddGranted(resourceName) ||
            this.isEditGranted(resourceName)
        )
            return true;

        return false;
    }

    isSubDeleteGranted(
        resourceName: PermissionResourcesEnum,
        objectId: number | null = null
    ) {
        if (this._bypass) {
            return true;
        }
        if (this.isEditGranted(resourceName, objectId)) return true;

        return false;
    }

    grantNewObjectHourPermissions(
        resourceName: PermissionResourcesEnum,
        objectId: number
    ) {
        this.oneHourPermissions.push({
            dateCreated: new Date().toISOString(),
            objectId: objectId,
            resourceName: resourceName,
        });
        clearInterval(this.oneHourInterval);
        this.manageInterval();
        this.rassignSessionData();
    }

    private manageInterval() {
        this.manageOneHourPermissions();
        this.oneHourInterval = setInterval(() => {
            this.manageOneHourPermissions();
        }, 60000);
    }

    rassignSessionData() {
        window.sessionStorage.removeItem(ONE_HOUR_PERMISSIONS_KEY);
        window.sessionStorage.setItem(
            ONE_HOUR_PERMISSIONS_KEY,
            JSON.stringify(this.oneHourPermissions)
        );

        this.oneHourPermissions = window.sessionStorage.getItem(
            ONE_HOUR_PERMISSIONS_KEY
        )
            ? JSON.parse(
                window.sessionStorage.getItem(ONE_HOUR_PERMISSIONS_KEY) ?? ''
            )
            : [];
    }

    private manageOneHourPermissions() {
        this.oneHourPermissions = this.oneHourPermissions.filter(
            (el) =>
                this.dateService.timeDiff(
                    new Date().toISOString(),
                    el.dateCreated) < HOUR_IN_MS
        );
        if (this.oneHourPermissions.length == 0) {
            clearInterval(this.oneHourInterval);
            this.rassignSessionData();
        }
    }
}

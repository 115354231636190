import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse, ApiResponseAny } from 'src/app/core/models/api-response.model';

import { environment } from 'src/environments/environment';
import { MembersList, RoleModule, RoleWithPermission } from '../../roles/models/role.model';
import { Role } from '../../roles/models/role.model';
import { AuthorizePermission, RoleList } from '../models/role-sys.model';
import { tcRequireCompany } from 'src/app/core/interceptors/auth.interceptor';
import { lastValueFrom } from 'rxjs';
import { GetDataOptions } from 'src/app/shared/model/shared.model';

const APIURL = `${environment.apiUrl}/sys/Authorize/`;

@Injectable({
    providedIn: 'root'
})
export class RoleSysApiService {

    constructor(
        private http: HttpClient
    ) { }

    async deleteRole(roleId: number): Promise<ApiResponseAny> {
        const response = await lastValueFrom(this.http
            .delete<ApiResponseAny>(`${APIURL}Role/${roleId}`,{ context: tcRequireCompany(false) }));
        return response;
    }

    async getAllRoles(options?: GetDataOptions): Promise<ApiResponse<RoleList[]>> {
        let response = await lastValueFrom(this.http
            .get<ApiResponse<RoleList[]>>(
                `${APIURL}Role/FpList?searchString=${
                    options?.searchString ?? ''
                }&orderBy=${options?.sort ?? ''}`,
                { context: tcRequireCompany(false) }
            ));

        return response;
    }

    async getAuthorizePermission(options?: GetDataOptions): Promise<ApiResponse<AuthorizePermission>> {
        let response = await lastValueFrom(this.http
            .get<ApiResponse<AuthorizePermission>>(
                `${APIURL}Permission/FpList`,
                { context: tcRequireCompany(false) }
            ));

        return response;
    }

    async getAllModules(
        roleId: number,
        searchString: string = ''
    ): Promise<ApiResponse<RoleModule[]>> {
        let response = await lastValueFrom(this.http
            .get<ApiResponse<RoleModule[]>>(
                `${APIURL}Role/${roleId}/Permission/FpList?searchString=${searchString}`,{ context: tcRequireCompany(false) }
            ));
        return response;
    }

    async getRoleDetails(roleId: number): Promise<ApiResponse<Role>> {
        let response = await lastValueFrom(this.http
            .get<ApiResponse<Role>>(`${APIURL}Role/${roleId}`,{ context: tcRequireCompany(false) }));

        return response;
    }

    async addRole(roleModel: Role): Promise<ApiResponseAny> {
        const response = await lastValueFrom(this.http
            .post<ApiResponseAny>(`${APIURL}Role`, roleModel,{ context: tcRequireCompany(false) }));

        return response;
    }

    async updateRoleWithPermission(
        roleId: number,
        rolesModel: RoleWithPermission
    ): Promise<ApiResponseAny> {
        const response = await lastValueFrom(this.http
            .put<ApiResponseAny>(
                `${APIURL}Role/${roleId}/Permission/FpList`,
                rolesModel,
                { context: tcRequireCompany(false) }
            ));

        return response;
    }

    async updateRoleDetails(
        roleId: number,
        roleModel: Role
    ): Promise<ApiResponseAny> {
        const response = await lastValueFrom(this.http
            .put<ApiResponseAny>(`${APIURL}Role/${roleId}`, roleModel,{ context: tcRequireCompany(false) }));
        return response;
    }

    /* Members */
    async getMembersList(roleId: number): Promise<MembersList[]> {
        const response = await lastValueFrom(this.http
            .get<ApiResponse<MembersList[]>>(`${APIURL}Role/${roleId}/FpList`,{ context: tcRequireCompany(false) }));

        return response.data as MembersList[];
    }

    async updateMembers(
        roleId: number,
        membersIds: number[]
    ): Promise<ApiResponseAny> {
        const response = await lastValueFrom(this.http
            .post<ApiResponseAny>(`${APIURL}Role/${roleId}/User`, {
                usersIds: membersIds,
            },{ context: tcRequireCompany(false) }));

        return response;
    }

}

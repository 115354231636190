import { BaseLoginProvider, SocialUser } from '@abacritt/angularx-social-login';
import { environment } from 'src/environments/environment';

declare let AppleID: any;

export class AppleLoginProvider extends BaseLoginProvider {
    public static readonly PROVIDER_ID: string = 'APPLE';

    protected auth2: any;

    constructor(
        private clientId: string,
        private _initOptions: any = { scope: 'email name' }
    ) {
        super();
    }

    public initialize(): Promise<void> {
        return new Promise((resolve, _reject) => {
            this.loadScript(
                AppleLoginProvider.PROVIDER_ID,
                'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js',
                () => {
                    const data = AppleID.auth.init({
                        clientId: this.clientId,
                        scope: 'name email',
                        redirectURI: environment.webUrl,
                        state: 'Initial user authentication request', //used to prevent CSFR
                        usePopup: true,
                    });
                    resolve();
                }
            );
        });
    }

    public getLoginStatus(): Promise<SocialUser> {
        return new Promise((resolve, reject) => {
            // todo: implement
            let user: SocialUser = new SocialUser();

            //   let profile = this.auth2.currentUser.get().getBasicProfile();
            //   let authResponse = this.auth2.currentUser.get().getAuthResponse(true);

            // user.id = profile.getId();
            // user.name = profile.getName();
            // user.email = profile.getEmail();
            // user.photoUrl = profile.getImageUrl();
            // user.firstName = profile.getGivenName();
            // user.lastName = profile.getFamilyName();
            // user.authToken = token;
            // user.idToken = backendToken;

            // user.response = authResponse;

            resolve(user);
        });
    }

    public async signIn(signInOptions?: any): Promise<SocialUser> {
        return new Promise(async (resolve, reject) => {
            const offlineAccess: boolean =
                signInOptions && signInOptions.offline_access;

            try {
                const data = await AppleID.auth.signIn();
                let user: SocialUser = new SocialUser();

                //   let profile = this.auth2.currentUser.get().getBasicProfile();
                //   let authResponse = this.auth2.currentUser.get().getAuthResponse(true);

                user.provider = AppleLoginProvider.PROVIDER_ID;
                user.idToken = data.authorization.id_token;
                user.authorizationCode = data.authorization.code;
                // user.authToken = token;

                // user.id = profile.getId();
                // user.name = profile.getName();
                if (data.user) {
                    user.email = data.user.email;
                    if (data.user.name) {
                        user.firstName = data.user.name.firstName;
                        user.lastName = data.user.name.lastName;
                    }
                }
                // user.photoUrl = profile.getImageUrl();
                user.response = data;

                resolve(user);
            } catch (er) {
                reject(er);
            }
        });
    }

    public signOut(revoke?: boolean): Promise<void> {
        return new Promise((resolve, reject) => {
            // AppleID doesnt have revoke method
            resolve();
        });
    }
}

import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class DateService {
    readonly isoLocalDateRegex = /^(?:\d{4}-\d{2}-\d{2})$/;
    readonly isoUTCDateTimeRegex = /^(?:\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d+)?Z?)$/;
    readonly isoUTCDateTimeWZRegex = /^(?:\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d+)?Z)$/;
    readonly isoLocalDateTimeRegex = /^(?:\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d+)?)$/;

    constructor() {}

    // Returns a date for the following input parameters:
    //  - date: returns a date and optionally reset the UTC hours
    //  - string with ISO format: convert it to date and optionally reset the UTC hours
    //  - string with ISO format (missing Z): add the Z, convert it to date and optionally reset the UTC hours
    //  - string with non-ISO format: convert it to date and optionally reset the UTC hours
    public toDate(aDate: Date | string | null | undefined): Date | null {
        if (aDate) {
            if (typeof aDate == 'string') {
                if (this.isoUTCDateTimeRegex.test(aDate)) {
                    aDate = new Date(aDate + (aDate.slice(-1) !== 'Z' ? 'Z' : ''));
                } else {
                    aDate = new Date(aDate);
                }
            }
            return aDate;
        }
        return null;
    }

    // Converts string, date, or null into an ISO formatted string
    toISOString(date: Date | string | null | undefined): string | null {
        return this.toDate(date)?.toISOString() ?? null;
    }

    // Return the local date in ISO format (ISO format usually in UTC)
    fakeISOString(date: Date | string | null | undefined, isFromServer = true, isResetTime = true, isTrimTime = false, isTrimUtcZ = false): string | null {
        date = this.toDate(date);
        if (date) {
            let fakeLocalDate: Date;
            if (isFromServer) { // Fake the date to make its Local looks like Local UTC
                const cookFakeLocalDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
                if (isResetTime) cookFakeLocalDate.setHours(0, 0, 0, 0);
                // then generate a string of the UTC form
                fakeLocalDate = cookFakeLocalDate;
            } else { // Fake the date to make its UTC looks like Local date time
                const cookFakeLocalDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
                if (isResetTime) cookFakeLocalDate.setUTCHours(0, 0, 0, 0);
                // then generate a string of the UTC form
                fakeLocalDate = cookFakeLocalDate;
            }

            if (isTrimTime) {
                return this.formatDateAfterUTC(date);
            } else {
                let fakeLocalDateString = fakeLocalDate.toISOString();
                if (isTrimUtcZ) fakeLocalDateString = fakeLocalDateString.slice(0, -1);
                return fakeLocalDateString;
            }
        }
        return null;
    }

    formatDateAfterUTC(date: Date): string {
        const year = date.getUTCFullYear();
        const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
        const day = date.getUTCDate().toString().padStart(2, '0');
      
        return `${year}-${month}-${day}`;
    }

    formatDate(date: Date): string {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
      
        return `${year}-${month}-${day}`;
    }

    /////////////////////////////
    ////////////BEGIN REMOVE///////////
    // dateToLocalISO(date: Date, isResetTime = false, isTrimUtcZ = true): string | null {
    //     if (date) {
    //         const fakeLocalDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
    //         if (isResetTime) fakeLocalDate.setUTCHours(0, 0, 0, 0);
            
    //         let fakeLocalDateString = fakeLocalDate.toISOString();
    //         if (isTrimUtcZ) fakeLocalDateString = fakeLocalDateString.slice(0, -1);
            
    //         return fakeLocalDateString;
    //     }
    //     return null;
    // }

    // utcToLocal(date: string | Date | undefined | null, hasTime: boolean = false): string | null {
    //     if (date) {
    //         date = this.toDate(date);

    //         const dateISO = this.dateToLocalISO(date);
    //         if (!hasTime) {
    //             return this.datePipe.transform(dateISO, 'yyyy-MM-dd');
    //         }
            
    //         if (dateISO)
    //             return dateISO.substring(0, 16);
    //     }

    //     return null;
    // }

    // public utcToDate(utcDateStr: string, isResetTIme = false): Date {
    //     let date = new Date(utcDateStr);
    //     if (this.isoUTCDateTimeRegex.test(utcDateStr)) {
    //         date = new Date(utcDateStr + (utcDateStr.slice(-1) !== 'Z' ? 'Z' : ''));
    //     }
    //     if (isResetTIme) date.setUTCHours(0, 0, 0, 0);
    //     return date;
    // }

    // public oldToDate(aDate: Date | string): Date {
    //     return (typeof aDate == 'string') ? this.utcToDate(aDate) : aDate;
    // }
    ////////////END REMOVE///////////
    /////////////////////////////

    //count: number = 0;
    dateDifference(endDate: Date, startDate: Date): string {
        var date2 = new Date(endDate);
        var date1 = new Date(startDate);
        // differnce in years
        var diffYear = (date2.getTime() - date1.getTime()) / 1000;
        diffYear /= 60 * 60 * 24;
        // differnece in months
        var diffMonth = (date2.getTime() - date1.getTime()) / 1000;
        diffMonth /= 60 * 60 * 24 * 7 * 4;
        // calculate the time difference of two dates JavaScript
        var diffTime = date2.getTime() - date1.getTime();
        // calculate the number of days between two dates javascript
        var daysDiff = diffTime / (1000 * 3600 * 24);
        if (Math.abs(Math.round(diffYear / 365.25)) == 0) {
            if (Math.abs(Math.floor(diffMonth)) == 0) {
                // return days
                return Math.abs(Math.floor(daysDiff)) + ' days';
            } else {
                // return months
                return Math.abs(Math.floor(diffMonth)) + ' months';
            }
        } else {
            // return years
            return Math.abs(Math.round(diffYear / 365.25)) + ' years';
        }
    }

    dateDiffDays(endDate: string | Date, startDate: string | Date) {
        let date2 = this.toDate(endDate)!;
        let date1 = this.toDate(startDate)!;
        // calculate the time difference of two dates JavaScript
        var diffTime = date2.getTime() - date1.getTime();
        // calculate the number of days between two dates javascript
        var daysDiff = diffTime / (1000 * 3600 * 24);
        return daysDiff;
    }

    getTimeAgo(date: Date | string,hideAgo: boolean = false) {
        date = this.toDate(date)!;
        let firstDate: any = date;
        let today: any = new Date();
        let diff = Math.abs(today.getTime() - firstDate.getTime());
        const year = parseInt(
            '' + Math.ceil(diff / (1000 * 60 * 60 * 24)) / 365.25
        );
        const month = parseInt('' + diff / (1000 * 60 * 60 * 24 * 7 * 4));
        const days = parseInt('' + diff / (1000 * 3600 * 24));
        const hours = parseInt('' + diff / (1000 * 60 * 60));
        const minutes = parseInt('' + diff / (1000 * 60));
        if (year == 0) {
            if (month == 0) {
                if (days == 0) {
                    if (hours < 24 && hours >= 1) {
                        return hours + ` hours ${hideAgo ? '' : 'ago'}`;
                    } else {
                        if (minutes == 0) {
                            return 'now';
                        }
                        return minutes + ` minutes ${hideAgo ? '' : 'ago'}`;
                    }
                } else {
                    return days + ` days ${hideAgo ? '' : 'ago'}`;
                }
            } else {
                return month + ` month ${hideAgo ? '' : 'ago'}`;
            }
        } else {
            return year + ` year ${hideAgo ? '' : 'ago'}`;
        }
    }

    toMill(date: Date | string | number): number {
        return (typeof date == 'number') ? date : this.toDate(date)!.getTime();
    }

    timeDiff(
        first: string | number,
        second: string | number): number {
        return this.toMill(first) - this.toMill(second);
    }

    timeAdd(
        first: string | number,
        second: string | number): number {
        return this.toMill(first) + this.toMill(second);
    }

    millisecondToDateString(millisecond: number) {
        let hours, mins, seconds: number;
        hours = Math.floor(millisecond / 3600000); // 60 * 60 * 1000 = 3,600,000
        mins = Math.floor(millisecond / 1000 / 60) - hours * 60;
        seconds = Math.floor((millisecond / 1000) % 60);
        let hoursStr = hours < 10 ? `0${hours}` : hours;
        let minStr = mins < 10 ? `0${mins}` : mins;
        let secStr = seconds < 10 ? `0${seconds}` : seconds;
        return `${hoursStr}:${minStr}:${secStr}`;
    }

    millisecondToDateObj(millisecond: number) {
        let hours, mins, seconds: number;
        hours = Math.floor(millisecond / 3600000); // 60 * 60 * 1000 = 3,600,000
        mins = Math.floor(millisecond / 1000 / 60) - hours * 60;
        seconds = Math.floor((millisecond / 1000) % 60);
        let hoursStr = hours < 10 ? `0${hours}` : hours;
        let minStr = mins < 10 ? `0${mins}` : mins;
        let secStr = seconds < 10 ? `0${seconds}` : seconds;
        return {
            hoursStr: Number(hoursStr),
            minStr: minStr,
            secStr: secStr
        }
    }
}
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ApiResponseAny } from 'src/app/core/models/api-response.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom } from 'rxjs';

interface Feature {
    id?: number;
    featureName?: string;
    response?: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class FeatureService {
    constructor(
        private spinner: NgxSpinnerService,
        private httpClient: HttpClient
    ) {}

    featureList$: BehaviorSubject<Feature[]> = new BehaviorSubject<Feature[]>(
        []
    );

    featureList: Feature[] = [];

    async getAllFeatures() {
        // this.spinner.show();
        try {
            let userId = window.localStorage.getItem('auth-user-id');
            let env = environment.envName == 'dev' ? 'development'
                : environment.envName == 'stag' ? 'staging'
                : environment.envName == 'uat' ? 'uat'
                : 'production';
            let result = await lastValueFrom(this.httpClient
                .get<ApiResponseAny>(
                    `https://feature.truckincentral.com/api/Feature/Environment?userId=${userId}&environment=${env}`
                ));
            if (result.success) {
                this.featureList = result.data;
                this.featureList$.next(result.data);
            }
            return [];
        } catch (err) {
            return [];
        } finally {
            // this.spinner.hide();
        }
    }

    checkAccess(featureName: string): boolean {
        if (environment.featureToggleBypass) return true;
        if (this.featureList.length > 1) {
            let feature = this.featureList.find(
                (e) => e.featureName == featureName
            );
            if (feature) return feature?.response as boolean;
            return false;
        } else {
            return false;
        }
    }

    restFeatures() {
        this.featureList = [];
        this.featureList$.next([]);
    }
}
